@import "minima";

$ava-gap: 4px;

.site-title::after {
    content: "";

    background: url('/assets/images/ava.gif');
    background-size: cover;

    display: inline-block;
    vertical-align: bottom;

    margin: $ava-gap 0 $ava-gap 0.5em;
    width: $base-line-height * $base-font-size * 2.25 - $ava-gap * 2;
    height: $base-line-height * $base-font-size * 2.25 - $ava-gap * 2;

    border-radius: 5px;
}
